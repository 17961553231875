import axios from 'axios';
import {
    Message,
    Loading
} from 'element-ui';
import store from '@/store';
// eslint-disable-next-line
import { postToken } from '@/api/account';

const {
    VUE_APP_API_BASE_URL,
    // eslint-disable-next-line
    // VUE_APP_API_PROXY_KEY
} = process.env;

const instance = axios.create({
    baseURL: VUE_APP_API_BASE_URL,
    headers: {
        Reference: 'www.example.com',
    },
    // baseURL: VUE_APP_API_BASE_URL + VUE_APP_API_PROXY_KEY
    timeout: 5000,
    showGlobalMessage: true,
    showLoading: true
});

let loadingInstance = null;
instance.interceptors.request.use(async config => {
    const hashBaseUrl = 'https://hash-release.stars-mine.com/v2/';
    switch (config.url) {
        case 'account/qiniuToken':
            config.baseURL = hashBaseUrl;
            break;
    }

    config.showLoading && (loadingInstance = Loading.service());

    const {
        postToken: {
            // eslint-disable-next-line
            token,
            // eslint-disable-next-line
            expire
        },
        userInfo:
        {
            user_id,
            user_secret
        }
    } = store.state.userStore;
    config.headers['user-id'] = user_id;
    config.headers['user-secret'] = user_secret;


    // config.headers['X-CSRF-TOKEN'] = token;

    // if ((!expire || Date.now() >= expire) && config.url !== 'token') {
    //     const newToken = await postToken();
    //     store.commit('setPostToken', newToken);
    //     config.headers['X-CSRF-TOKEN'] = newToken;
    // }

    return config;
});

instance.interceptors.response.use(response => {
    loadingInstance.close();

    const {
        status,
        statusText,
        data,
        config: {
            showGlobalMessage,
        }
    } = response;

    // 网络错误 这是http status code
    if (status !== 200) {
        Message.error(`服务器未正常响应 状态码${status}`);
        throw new Error(statusText);
    }

    // 接口抛出的错误 人为干预的 http status code为200 但接口的custom field code非200的情况
    const { code, msg } = response.data;
    if (code !== 200 && showGlobalMessage === true) {
        Message.error(msg);
    }

    // console.log('response', response);
    return data;
}, error => {
    Message.error('网络异常,请稍后再试')
    // console.error('responseReject', error)
    throw new Error(error)
});



export const get = (url, params, headers, otherConfig) => instance.get(url, { params, headers, ...otherConfig });
export const post = (url, data, headers, otherConfig) => instance.post(url, data, { headers, ...otherConfig });
export default instance;