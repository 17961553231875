import { get, post } from '@/api/request';

// 用户登录
export const userLogin = (params) => post("userLogin", params);
// 用户注册
export const userRegister = (params) => post("userRegister", params);
// 获取指定用户信息
export const userInfoById = (params) => get("userInfoById", params, undefined, { showGlobalMessage: false });
// 更新当前用户信息
export const userUpdateInfo = (params) => post("userUpdateInfo", params, undefined, { showGlobalMessage: false });
// 七牛云token
export const qiniuToken = () => get("account/qiniuToken");
// 所有post请求需要带一个token（弃用）
export const postToken = () => get("token", undefined, undefined, { showGlobalMessage: false });
